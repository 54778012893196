import { getClass } from '../../utils';
import { IonLoading } from '@ionic/react';
import { parseStringToHTML } from '../../utils';
import { useFaqsQuery } from '../../@types/generated-gql-typed-hooks';
import _map from 'lodash/map';
import Header from '../Header';
import PageLayout from '../PageLayout';
import React from 'react';
import styles from './styles.module.scss';
import withTracker from '../withTracker';

const FAQs: React.FC = () => {
    const { data, loading } = useFaqsQuery();

    return (
        <PageLayout withBottomSpacing>
            <IonLoading isOpen={loading} message='Please wait...' />
            <Header pageTitle='FAQs' />
            <div className={getClass(styles, 'container')}>
                <div className={getClass(styles, 'content')}>
                    {_map(data?.faqs?.data, ({ attributes }, index: number) => (
                        <div className={styles.faq} key={index}>
                            <h4 className={styles.question}>{attributes?.question}</h4>
                            <div className={styles.answer}>{parseStringToHTML(attributes?.answer as string)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </PageLayout>
    );
};

export default withTracker(FAQs);
