import { getClass } from '../../utils';
import Header from '../Header';
import PageLayout from '../PageLayout';
import React from 'react';
import styles from './styles.module.scss';
import withTracker from '../withTracker';

const AboutUsComponent: React.FC<any> = () => {
    return (
        <PageLayout withBottomSpacing>
            <Header pageTitle='About us' />
            <div className={getClass(styles, 'container')}>
                <div className={getClass(styles, 'content')}>
                    <h2 className={`uproot-h2 ${styles.heading}`}>Hi! We&rsquo;re Uproot.</h2>
                    <p>
                        Our mission is to bridge the gap between social issues and legislative reform. We help people learn about how their
                        communities are governed and how to make the most of their vote.
                    </p>
                    <p>
                        When you know your elected officials and how they&apos;ve voted, you can make sure there&apos;s alignment between what you
                        care about and who represents you.
                    </p>
                    <p>We believe in working towards systemic change, and it starts with getting the information you need to amplify your voice.</p>
                </div>
            </div>
        </PageLayout>
    );
};

export default withTracker(AboutUsComponent);
