import { closeCircle, locationOutline } from 'ionicons/icons';
import { getClass } from '../../../utils';
import { InputProps } from '../Input/types';
import { IonIcon } from '@ionic/react';
import { useAutoCompleteLazyQuery } from '../../../@types/generated-gql-typed-hooks';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import GoogleAddressAutocompleteReact from 'google-address-autocomplete-react/dist';
import Input, { ConnectedInput, InputTypes } from '../Input';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

type BaseProps = Pick<InputProps, 'styleType' | 'className' | 'placeholder'> & {
    name?: string;
    label?: string;
    required?: boolean;
    description?: string;
    selectedAddress?: string;
    toggle?: boolean;
    toggleIcon?: any;
    onToggle?: () => void;
};

interface ConnectedComponentProps extends BaseProps {
    isConnected: boolean;
    onPlaceSelected?: never;
}

interface UnconnectedComponentProps extends BaseProps {
    isConnected?: never;
    onPlaceSelected: (place: string) => void;
}

/**
 * AddressAutoComplete component is either 'connected' or 'not connected
 * - meaning within context of an existing React-Hook-Form component or outside.
 *
 * If it's NOT within context of a Form, an optional 'onPlaceSelected'
 * callback function prop is exposed. Otherwise, set the 'isConnected' prop to true
 *
 * See:
 *
 * - [Form](https://gitlab.com/emilg/uproot-ui/-/blob/master/src/components/shared/Form/Form.tsx#L25)
 * - [ConnectedInput](https://gitlab.com/emilg/uproot-ui/-/blob/master/src/components/shared/Input/ConnectedInput/ConnectedInput.tsx)
 *
 */

const AddressAutocompleteComponent: React.FC<ConnectedComponentProps | UnconnectedComponentProps> = ({
    name = 'auto_complete',
    label,
    isConnected,
    required = false,
    onPlaceSelected,
    toggle = false,
    onToggle,
    styleType,
    placeholder,
    description,
    toggleIcon,
    selectedAddress,
}) => {
    const formContext = useFormContext();
    const [fetchAddresses, data] = useAutoCompleteLazyQuery();
    const [address, setAddress] = useState<string | undefined>(selectedAddress);

    const pinIcon = <IonIcon icon={locationOutline} className={styles.ionIcon} />;

    const toggleEdit = (): any => {
        setAddress('');
        isConnected && formContext.setValue(name, '');
        onToggle && onToggle();
    };

    const handleSelectAddress = (address: string) => {
        isConnected && formContext.setValue(name, address);
        onPlaceSelected && onPlaceSelected(address);
        !isConnected && toggle && toggleEdit();
    };

    const fetchAddressPredictions = async (inputValue: string) => {
        if (inputValue) {
            await fetchAddresses({ variables: { query: inputValue } });
            const predictions = data && (await get(data, 'data.fetchAutoCompleteAddresses', []));
            if (predictions.length) {
                return predictions;
            }
        }
    };

    useEffect(() => {
        setAddress(selectedAddress);
        isConnected && formContext.setValue(name, address);
    }, [selectedAddress]);

    return address ? (
        <div className={styles.editAddress}>
            <p className={getClass(styles, 'address')}>{selectedAddress}</p>
            <button className={styles.editButton} onClick={() => toggleEdit()}>
                <IonIcon icon={closeCircle} className={styles.ionIcon} />
            </button>
        </div>
    ) : (
        <div className={styles.addressAutocompleteWrapper}>
            <GoogleAddressAutocompleteReact
                fetchPredictions={fetchAddressPredictions}
                onAddressSelected={handleSelectAddress}
                pinIcon={pinIcon}
                toggleIcon={toggleIcon}
                userOnToggle={toggleEdit}
                onChangeName={'onIonInput'}
                CustomInput={isConnected ? ConnectedInput : Input}
                customInputProps={{
                    name,
                    label,
                    placeholder,
                    styleType,
                    type: InputTypes.Search,
                    required,
                    description,
                    autocomplete: 'off',
                    toggleEdit,
                }}
            />
        </div>
    );
};

export default AddressAutocompleteComponent;
