import { ConnectedInput, InputModeTypes, InputTypes } from '../Input';
import { ConnectedInputProps } from '../Input/types';
import React, { FC } from 'react';

type Props = Pick<ConnectedInputProps, 'name' | 'label' | 'required' | 'defaultValue' | 'disabled'>;

const EmailInputComponent: FC<Props> = ({ name, label, required, defaultValue, disabled }) => {
    // eslint-disable-next-line max-len, no-control-regex
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    return (
        <ConnectedInput
            name={name}
            label={label}
            type={InputTypes.Email}
            registerOptions={{
                pattern: {
                    value: regex,
                    message: 'Invalid email address',
                },
                required: Boolean(required),
            }}
            placeholder='example@email.com'
            inputMode={InputModeTypes.Email}
            disabled={disabled}
            defaultValue={defaultValue}
        />
    );
};

export default EmailInputComponent;
